import React, { useEffect } from 'react'
import { UseFormValidations } from '../../validations/UseFormValidation'
import { Modal } from 'react-bootstrap'

const CreateSpeciality = (props) => {
    const submit=()=>{
        data["specialityId"]=data?.specialityId?data?.specialityId:0
        data["organizationId"]=sessionStorage.getItem("organizationId")
        props?.submit(data)
    }
    const {data,errors,handleChange,handleNumberChange,handleAlphabetChange,handleSubmit,setValues}=UseFormValidations({
      initialValues:{
        specialityName:""
  
      },
      validationSchema:{
        specialityName: {
          required: {
            value: true,
            message: "Please select specialityName",
          },
        },
  
      },
        submit:submit
    })
    const returnValue=(key)=>{
        return data?.[key]&&data?.[key]?data?.[key]:""
    }
    const ErrorValue=(key)=>{
    return errors?.[key]&&errors?.[key]?" form-control border border-danger":"form-control"
    }
    useEffect(()=>{
        if (props?.show?.specialityId){
            setValues(props?.show)
        }
    },[props?.show?.specialityId])
  return (
    <Modal  {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <Modal.Header >
      <Modal.Title>Add Speciality</Modal.Title>
    </Modal.Header>
   <form onSubmit={handleSubmit}>
   <Modal.Body>
     <div className='row'>
<div>
    <label>Speciality Name <span className='text-danger'>*</span></label>
    <input className={ErrorValue("specialityName")} placeholder='Speciality Name' value={returnValue("specialityName")} onChange={handleAlphabetChange("specialityName")}/>

</div>



     </div>
    </Modal.Body>
    <Modal.Footer>
      <button className='btn border px-5' onClick={()=>props?.onHide()} >
        Close
      </button>
      <button className='bg_btn btn text-white px-5'>
        Save 
      </button>
    </Modal.Footer>
   </form>
  </Modal>
  )
}

export default CreateSpeciality