import React, { useEffect, useState } from 'react'
import { GetListById, icons, save } from '../../components/ShareComp'
import ReactTableShare from '../../components/ReactTableShare'
import CreateSpeciality from './CreateSpeciality'
import { url } from '../../services/Urls'

const SpecialityList = () => {
    const [list,setList]=useState([])
    const [search,setSearch]=useState("")
    const [model,setModel]=useState(false)
    const [update,setUpdate]=useState([])
    const getAllScan=async()=>{
      let res=await GetListById(url.getAllSpeciality,{id:0})
    
      setList(res)
    }
    const submit=async(obj)=>{
      const res=await save(url?.saveSpeciality,obj);
  setUpdate(res)
  setModel(false)
    }
    
    const columns=[
      {name:"Speciality Name",selector:(v)=>v.specialityName,sortable:true,width:""},
     
      {name:"Action",selector:(v)=><div className='ptr' onClick={()=>setModel(v)}>{icons?.edit}</div>,width:""},
  
    ]
    
    const handleChang = () =>(e)=> {
      setSearch(e.target.value)
    }
  
    const listsearch = () => {
      return (<div className=' d-flex flex-wrap ' style={{ width: "100%" }}>
        
       <div className='col'>
       <input type="search"  className="form-control search-control search-bg "
          value={search!=""?search:""} onChange={handleChang()} placeholder="Search Speciality..." />
       </div>
        
       
      </div>)
    }
    useEffect(()=>{getAllScan()},[update])
    return (
      <div >
          <div className='d-flex flex-wrap py-1'>
        <div className='col-md-6 px-2 heading_list '>Speciality</div>
        <div className='col-md-6 d-flex flex-wrap gap-2 justify-content-end'>
        
        <div className='btn bg_btn col-auto text-white d-flex gap-2 align-items-center' onClick={()=>setModel(!model)}>
          {icons.add}<span className='color2'>Add Speciality</span>
        </div>
        </div>
        </div>
      <div className='py-2'><ReactTableShare dataTable={list} columns={columns||[]} search1={listsearch} search={search}/></div>
        { model&&<CreateSpeciality show={model} onHide={()=>setModel(false)} submit={submit}/>}
      </div>
    )
}

export default SpecialityList