import React, { useEffect } from 'react'
import { UseFormValidations } from '../../validations/UseFormValidation'
import { Modal } from 'react-bootstrap'
import Select from "react-select"
import { checkRole } from '../../components/ShareComp'
const Add = (props) => {
    const submit=()=>{
        data["id"]=data?.planId?data?.planId:0
        data["services"]=JSON.stringify(data?.services)
        props?.submit(data)
    }
    const listTwo=[
      {label: "All analytics features",value:"1"},
      { label:"Up to 250,000 tracked visits",value:"2"},
       {label:"Normal support",value:"3"},
       {label:"Up to 3 team members",value:"4"}
   ]
    const {data,errors,handleChange,handleNumberChange,handleAlphabetChange,handleMultiSelectDropdown,handleSubmit,setValues}=UseFormValidations({
      initialValues: {
        planName: "",
        amount: "",
        totalScans: "",
        planValidity:""
      },
      validationSchema: {
        planName: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        amount: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        totalScans: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        planValidity: {
          required: {
            value: true,
            message: "Please enter your First Name",
          },
        },
        
      },
        submit:submit
    })
    const returnValue=(key)=>{
        return data?.[key]&&data?.[key]?data?.[key]:""
    }
    const ErrorValue=(key)=>{
    return errors?.[key]&&errors?.[key]?" form-control border border-danger":"form-control"
    }
    useEffect(()=>{
        if (props?.show?.planId){
            setValues(props?.show)
        }
    },[props?.show?.planId])
  return (
    <Modal  {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <Modal.Header >
      <Modal.Title>{props?.show?.planId?"Edit Subcription":"Add Subcription"}</Modal.Title>
    </Modal.Header>
   <form onSubmit={handleSubmit}>
   <Modal.Body>
     <div className='row'>
<div>
    <label>Package Name<span className='text-danger'>*</span></label>
    <input className={ErrorValue("planName")} placeholder='Name' value={returnValue("planName")} onChange={handleAlphabetChange("planName")}/>

</div>
<div>
    <label>Amount<span className='text-danger'>*</span></label>
    <input className={ErrorValue("amount")} placeholder='Amount'value={returnValue("amount")} onChange={handleNumberChange("amount")}/>
    
</div>
<div>
    <label>Scans Limit<span className='text-danger'>*</span></label>
    <input className={ErrorValue("totalScans")} placeholder='Scans Limit'value={returnValue("totalScans")} onChange={handleNumberChange("totalScans")}/>
    
</div>
<div>
    <label>Plan Validity<span className='text-danger'>*</span></label>
    <select className={`${ErrorValue("planValidity")} form-select`} placeholder='Scans Limit'value={returnValue("planValidity")} onChange={handleChange("planValidity")}>
        <option value={""}>Select</option>
        <option value={"30"}>30 days</option>
        <option value={"90"}>90 days</option>
        <option value={"365"}>365 days</option>
    </select>
    
</div>
<div>
<label>What's Includes</label>
<Select isMulti options={listTwo} value={returnValue("services")} onChange={handleMultiSelectDropdown("services")}/>
</div>
<div>
    <label>Description</label>
    <textarea className={ErrorValue("description")} placeholder='Description'value={returnValue("description")} onChange={handleChange("description")}/>
    
</div>
     </div>
    </Modal.Body>
    <Modal.Footer>
      <button className='btn border px-5' onClick={()=>props?.onHide()} >
        Close
      </button>
     { !checkRole()&&<button className='bg_btn btn text-white px-5'>
        Save 
      </button>}
    </Modal.Footer>
   </form>
  </Modal>
  )
}

export default Add