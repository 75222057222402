import React, { useEffect, useState } from "react";
import Banner from "../assets/images/christopher-campbell-kFCdfLbu6zA-unsplash 1.png";
import Banner2 from "../assets/images/Banner.png";
import Banner3 from "../assets/images/login_blue4.png";
import logo from "../assets/images/navlogo.png";
import { UseFormValidations } from "../validations/UseFormValidation";
import { useNavigate } from "react-router-dom";
import { icons, notify, save } from "./ShareComp";
import { imageUrl, url } from "../services/Urls";
import { Modal } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";
import { RiDownloadCloudFill } from "react-icons/ri";
import { HiVideoCamera } from "react-icons/hi";
const Login = () => {
  const [fgt, setFgt] = useState(true);
  const navigateTo = useNavigate();
  const [password, setPassword] = useState(true);
  const [modal, setModal] = useState(false);
  const submit = async () => {
    let payload = data;

    let res = await save(fgt?url?.Login:url?.forgotPassword?.sentEmail, fgt?payload:{email:data?.email});
   
    sessionStorage.setItem("token", res?.data?.token);
    sessionStorage.setItem("email", res?.data?.user?.email);
    sessionStorage.setItem("firstName", res?.data?.user?.firstName);
    sessionStorage.setItem("lastName", res?.data?.user?.lastName);
    sessionStorage.setItem("phone", res?.data?.user?.phone);
    sessionStorage.setItem("roleId", res?.data?.user?.roleId);

    sessionStorage.setItem("status", res?.data?.user?.status);
    sessionStorage.setItem("userId", res?.data?.user?.userId);
    sessionStorage.setItem("createdDate", res?.data?.user?.createdDate);
    sessionStorage.setItem("scansValidity", res?.data?.user?.scansValidity);
    sessionStorage.setItem("scansLimit", res?.data?.user?.scansLimit);
    sessionStorage.setItem("planId", res?.data?.user?.planId);

    if (res?.data?.user?.roleId && res?.data?.user?.roleId != 5&&res?.data?.user?.roleId != 4) {
      navigateTo("/bluhealth/dashboard");
    } 
 
    else {
      res?.data?.user?.roleId == 5 && navigateTo("/dashboard");
    }
  };

  const { data, errors, handleEmailChange, handleSubmit } =
    UseFormValidations({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: {
        email: {
          required: {
            value: true,
            message: "Please enter a valid E-mail",
          },
          pattern: {
            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
            message: "Please enter a valid E-mail",
          },
        },
        password: {
          required: {
            value: true,
            message: "Please enter the correct password",
          },
        },
      },
      submit: submit,
    });
  const errorsMsg = (key) => {
    return errors && errors?.[key] ? errors?.[key] : "";
  };
  const errorsColor = (key) => {
    return errors && errors?.[key] ? "form-control  py-3 border border-danger" : "form-control  py-3";
  };
  const handlePass = () => {
    setPassword(!password);
  };
 
  useEffect(()=>{
if(fgt==false){
  data["password"]="123456"
 
}
  },[fgt])
  console?.log(data,fgt)
  return (
    <div className="" style={{ overflow: "hidden" }}>
      <form onSubmit={handleSubmit} className="row">
        <div className="col-md-6">
          
          <div className="your-container2"></div>
        </div>
        <div className="col-md-6  px-2  d-flex flex-wrap justify-content-center  align-items-center">
        
          <div
            className="col-md-8 d-flex flex-wrap justify-content-center  align-items-center shadow pb-4 "
            style={{ marginRight: "2.5%", }}
          >
            <div className="col-auto py-3 d-flex justify-content-center align-items-center ">
              <img
                src={logo}
                className="img-fluid "
                style={{ maxHeight: "100px" }}
              />
             
            </div>

            <p className="col-md-10 p_1 ">Nice to see you again</p>
            <div className="col-md-10 py-1">
              <label>Email</label>
              <input
                className={errorsColor()}
                placeholder="Enter Email"
                onChange={handleEmailChange("email")}
              />
              {/* <div className="text-danger">{errorsMsg("email")}</div> */}
            </div>
            {fgt&&<div className="col-md-10 py-1 ">
              <label className="col-md-10 ">Password</label>
              <div className="password-container ">
                <input
                  type={password ? "password" : "text"}
                  className={`${errorsColor()} password-input`} 
                  name="password"
                  placeholder="Enter Password"
                  value={data?.password}
                  onChange={handleEmailChange("password")}
                />
                <span onClick={handlePass} className="eye-icon">
                  {password ? icons.eyeClose : icons.eyeOpen}
                </span>
              </div>
              {/* <div className="text-danger">{errorsMsg("password")}</div> */}
             
            </div>
            }
             <p
                className="text-end py-1 text-primary col-10"
                onClick={() => setFgt(!fgt)}
              >
                <span className="ptr">
                  {fgt ? "Forgot Password?" : "Sign In"}
                </span>
              </p>

            <div className="col-md-10 py-1">
              <button type="submit" className="btn bg_btn col-md-12 text-white ">
                {fgt ? "Sign In" : "Submit"}
              </button>
            </div>
          </div>
          
        </div>
      </form>
     
    </div>
  );
};

export default Login;
